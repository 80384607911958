/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { FC } from 'react';
import React, { Fragment, useCallback, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { G400 } from '@atlaskit/theme/colors';

import {
	UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { getLogger } from '@confluence/logger';
import {
	CommentButtonRefetchQuery,
	ResolveInlineCommentMutation,
} from '@confluence/inline-comments-queries';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

import { ResolvedNotificationContainer, ResolvedNotificationTitle } from './styled-components';
import type { InlineCommentsMode } from './inlineCommentsTypes';
import { CommentErrorMessage } from './CommentErrorMessage';
import { InlineCommentMode } from './enum/InlineCommentMode';
import { InlineCommentFramework } from './enum/InlineCommentFramework';

const i18n = defineMessages({
	resolvedCommentDescription: {
		id: 'inline-comments-common.resolved-comment-description',
		description: 'Message to be displayed when user Resolves a comment',
		defaultMessage: 'You can view all the comments from the More actions menu.',
	},
	resolvedCommentTitle: {
		id: 'inline-comments-common.resolved-comment-title',
		description: 'Title to be displayed when user Resolves a comment',
		defaultMessage: 'Comment Resolved',
	},
});

type ResolvedCommentNotificationProps = {
	commentId: string;
	reopenComment: (markerRef?: string) => void;
	pageId: string;
	inlineMarkerRef: string;
	mode: InlineCommentsMode;
	isRendererAnnotationProviderEnabled?: boolean;
};

const logger = getLogger('resolved-comment-notification');

export const ResolvedCommentNotification: FC<ResolvedCommentNotificationProps> = ({
	commentId,
	reopenComment,
	pageId,
	inlineMarkerRef,
	mode,
	isRendererAnnotationProviderEnabled,
}) => {
	const refetchQueries =
		mode !== 'view-all' ? [{ query: CommentButtonRefetchQuery, variables: { pageId } }] : [];

	const [reopenInlineCommentFn] = useMutation(ResolveInlineCommentMutation, {
		refetchQueries,
	});

	const [error, setError] = useState<Error | null>(null);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const isLivePage = useIsLivePage();

	const getCommentModeForAnalytics = useCallback(() => {
		return mode === InlineCommentMode.EDIT
			? isLivePage
				? InlineCommentMode.LIVE
				: InlineCommentMode.EDIT
			: InlineCommentMode.VIEW;
	}, [mode, isLivePage]);

	const unresolveComment = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();

			const mtnVariables = {
				variables: { commentId, resolved: false },
			};

			experienceTracker.start({
				name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
				id: commentId,
				attributes: {
					mode: getCommentModeForAnalytics(),
					framework:
						mode === InlineCommentMode.EDIT || isRendererAnnotationProviderEnabled
							? InlineCommentFramework.ANNOTATION_PROVIDER
							: InlineCommentFramework.REACT,
				},
			});

			reopenInlineCommentFn({
				...mtnVariables,
			})
				.then(() => {
					reopenComment(inlineMarkerRef);

					experienceTracker.succeed({
						name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
					});
				})
				.catch((error) => {
					logger.error`An error ocurred when reopening a comment ${error}`;

					setError(error);
					experienceTracker.stopOnError({
						name: UNRESOLVE_INLINE_COMMENT_EXPERIENCE,
						error,
					});
				});
		},
		[
			commentId,
			reopenComment,
			reopenInlineCommentFn,
			inlineMarkerRef,
			experienceTracker,
			isRendererAnnotationProviderEnabled,
			getCommentModeForAnalytics,
			mode,
		],
	);

	return (
		<Fragment>
			<ResolvedNotificationContainer>
				<CheckCircleIcon
					size="xlarge"
					primaryColor={token('color.icon.success', G400)}
					label=""
					testId="inline-comments.confirm-icon"
				/>
				<ResolvedNotificationTitle>
					<FormattedMessage {...i18n.resolvedCommentTitle} />
				</ResolvedNotificationTitle>
				<FormattedMessage {...i18n.resolvedCommentDescription} tagName="p" />
				<a onClick={unresolveComment}>
					<FormattedMessage
						id="inline-comments-common.undo-resolved-comment"
						defaultMessage="Undo resolved comment"
						description="Undo Resolved comment"
					/>
				</a>
			</ResolvedNotificationContainer>
			{error && <CommentErrorMessage error={error} action="reopen" />}
		</Fragment>
	);
};
